<template>
  <div>
    <Header />
    <router-view/>
  </div>
</template>

      <!-- <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> -->



<script>
import Header from "./views/layouts/Header"
export default {
  components: {
    Header
  },
  data(){
    return {

    }
  },
  created(){
    this.$store.dispatch('getGenres');
  }
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');

/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;600&display=swap');
 :root {
   --font-primary: "Montserrat";
 } */
 input:focus, textarea:focus, select:focus{
    outline: none;
  }

#app {
  font-family: 'Montserrat', sans-serif;
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
  /* text-align: center; */
  /* color: #2c3e50; */
}

/* #nav {
  padding: 30px;
} */

/* #nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
} */
</style>
